const ApiRestBaseUrl = 'https://api.itsovertime.com/api';
const ApiPlatformsBaseUrl = 'https://api.itsovertime.com/api/platforms';

// const ApiRestBaseUrl = 'http://localhost:8081/api';
// const ApiPlatformsBaseUrl = 'http://localhost:8082/api/platforms';

export enum Urls {
	VivenuTicketsBaseUrl = 'https://tickets.itsovertime.com',
	VivenuTicketsSecretShopUrl = 'https://tickets.itsovertime.com/section/ot7-website-hyz1',

	OvertimeShopUrl = 'https://shop.overtime.tv',

	PlatformsYouTubeVideos = `${ApiPlatformsBaseUrl}/youtube/szn`,
	PlatformsYouTubeShorts = `${ApiPlatformsBaseUrl}/youtube/szn_shorts`,

	RestApiBaseUrl = `${ApiRestBaseUrl}`,

	YouTubeOvertimeSzn = 'https://www.youtube.com/@OvertimeSZN/featured',

	MediaCredentialsForm = 'https://forms.gle/gpK7Vvu7rQSBctWf6',

	OvertimeImagesBaseUrl = 'https://images.itsovertime.com',
}
