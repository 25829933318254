import React, { createElement, lazy } from 'react';
import { Route } from 'react-router-dom';
import Suspended from '~/src/components/Suspended';
import NotFound from '../components/pages/NotFound';

//This is a stupid hack to get Parcel to put shared element styles in app.css where they belong
import Common from '~/src/components/Common';
//@ts-ignore
const _ = Common;

const Index = lazy(() => import('~/src/components/pages/Index'));
const About = lazy(() => import('~/src/components/pages/About'));
const Schedule = lazy(() => import('~/src/components/pages/Schedule'));
const Standings = lazy(() => import('~/src/components/pages/Standings'));
const Teams = lazy(() => import('~/src/components/pages/Teams'));
const Team = lazy(() => import('~/src/components/pages/Team'));
const Events = lazy(() => import('~/src/components/pages/Events'));
const Event = lazy(() => import('~/src/components/pages/Event'));
const Media = lazy(() => import('~/src/components/pages/Media'));
const FanCodeOfConduct = lazy(() => import('~/src/components/pages/FanCodeOfConduct'));

const LazyElement = ({ element }: { element: React.LazyExoticComponent<any> }) => (
	<Suspended>{createElement(element)}</Suspended>
);

//Set key equal to element name so styles get included in SSR
export const Routes = (
	<Route>
		<Route path="/" element={<LazyElement key="Index" element={Index} />} />
		<Route path="/about" element={<LazyElement key="About" element={About} />} />
		<Route path="/schedule" element={<LazyElement key="Schedule" element={Schedule} />} />
		<Route path="/schedule/:section" element={<LazyElement key="Schedule" element={Schedule} />} />
		<Route path="/standings" element={<LazyElement key="Standings" element={Standings} />} />
		<Route path="/standings/:section" element={<LazyElement key="Standings" element={Standings} />} />
		<Route path="/standings/:section/:phase" element={<LazyElement key="Standings" element={Standings} />} />
		<Route path="/teams" element={<LazyElement key="Teams" element={Teams} />} />
		<Route path="/teams/:id" element={<LazyElement key="Team" element={Team} />} />
		<Route path="/events" element={<LazyElement key="Events" element={Events} />} />
		<Route path="/events/:id" element={<LazyElement key="Event" element={Event} />} />
		<Route path="/media" element={<LazyElement key="Media" element={Media} />} />
		<Route path="/fan-code-of-conduct" element={<LazyElement key="FanCodeOfConduct" element={FanCodeOfConduct} />} />

		<Route path="/not_found" element={<NotFound key="NotFound" />} />
		<Route path="*" element={<NotFound key="NotFound" />} />
	</Route>
);
