import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import { imgOt7LogoSvg } from '~/src/images';
import './Footer.scss';

type MenuItem = {
	text: string;
	url: string;
};

const leagueMenuItems: MenuItem[] = [
	{ text: 'Schedule', url: '/schedule' },
	{ text: 'Teams', url: '/teams' },
	{ text: 'Events', url: '/events' },
	{ text: 'Standings', url: '/standings' },
	{ text: 'About', url: '/about' },
];

const aboutMenuItems: MenuItem[] = [
	{ text: 'OT7', url: '/about' },
	{ text: 'Media & Credentials', url: '/media' },
	{ text: 'Fan Code of Conduct', url: '/fan-code-of-conduct' },
];

const Footer = () => {
	return (
		<footer className="Footer">
			<ContentContainer>
				<nav>
					<div className="LogoContainer">
						<a href="/">
							<img src={imgOt7LogoSvg} />
						</a>
					</div>

					<section>
						<div className="ColumnHeader">League</div>
						{leagueMenuItems.map((item, index) => (
							<a key={`footer-menu-${index}`} className="inherit" href={item.url}>
								{item.text}
							</a>
						))}
					</section>

					<section>
						<div className="ColumnHeader">About</div>
						{aboutMenuItems.map((item, index) => (
							<a key={`footer-about-menu-${index}`} className="inherit" href={item.url}>
								{item.text}
							</a>
						))}
					</section>

					<section>
						<div className="ColumnHeader">Follow</div>
						<div className="Social">
							<a className="inherit" href="https://www.instagram.com/ot7/" title="Instagram">
								<FontAwesomeIcon icon={faInstagram} size="1x" />
							</a>
							<a className="inherit" href="https://www.tiktok.com/@overtime7" title="TikTok">
								<FontAwesomeIcon icon={faTiktok} size="1x" />
							</a>
							<a className="inherit" href="https://www.youtube.com/@OvertimeSZN" title="YouTube">
								<FontAwesomeIcon icon={faYoutube} size="1x" />
							</a>
						</div>
					</section>
				</nav>
			</ContentContainer>
			<div className="LegalContainer">
				<ContentContainer>
					<div>&copy; {new Date().getFullYear()} Overtime. All Rights Reserved.</div>
					<div className="Links">
						<a className="inherit" href="https://overtime.tv/terms">
							Terms
						</a>
						<span>|</span>
						<a className="inherit" href="https://overtime.tv/privacy">
							Privacy Policy
						</a>
						<span>|</span>
						<a className="inherit" href="https://overtime.tv/copyright">
							DMCA
						</a>
					</div>
				</ContentContainer>
			</div>
		</footer>
	);
};
export default Footer;
