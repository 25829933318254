import _, { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { abbr } from 'us-state-converter';
import Button from '~/src/components/Button';
import Eyebrow from '~/src/components/Eyebrow';
import Game from '~/src/components/Index/Game';
import TBD from '~/src/components/TBD';
import { getDateTimeWithTimezone } from '~/src/lib';
import { Ot7Event } from '~/src/models/Ot7Event';
import { Ot7Game } from '~/src/models/Ot7Game';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { Urls } from '../../lib/enums/urls';
import './Games.scss';

const Games = ({
	showAll = false,
	multiColumn = false,
	event,
	team_id,
}: {
	showAll?: boolean;
	multiColumn?: boolean;
	event?: string;
	team_id?: string;
}) => {
	let event_id;
	if (event) {
		const fetchEvents = useFetch<Ot7Event>({
			url: `${Urls.RestApiBaseUrl}/ot7_events/v1/public/${event}`,
			key: 'ot7_event',
		});
		event_id = fetchEvents.result.read()?.id;
	}

	const fetchGames = useFetch<Ot7Game[]>({
		url: `${Urls.RestApiBaseUrl}/ot7_games/v1/public?all=true${event_id ? `&ot7_event_id=${event_id}` : ''}${
			team_id ? `&ot7_team_id=${team_id}` : ''
		}`,
		key: 'ot7_games',
	});
	const games = fetchGames.result.read();
	const upcomingGames = games?.filter((g) => DateTime.fromISO(g.starts_at).toISODate() >= DateTime.now().toISODate());
	const nextGame = _.orderBy(upcomingGames, 'starts_at', 'asc').at(0);
	const nextEvent = nextGame?.ot7_event;
	const gameDays = groupBy(
		games.filter((g) => (showAll ? true : g.ot7_event_id === nextEvent?.id)).slice(0, showAll ? games.length : 4),
		(g) => getDateTimeWithTimezone(g.starts_at, g.starts_at_timezone).set({ second: 0, millisecond: 0 }),
	);

	return (
		<div className="IndexGames">
			<h2>Games</h2>
			{Object.values(gameDays).length > 0 ? (
				Object.values(gameDays).map((games, i) => (
					<Fragment key={i}>
						<Eyebrow fullWidth={true}>
							{i === 0 ||
							new Date(Object.keys(gameDays)[i - 1]).getDay() !== new Date(Object.keys(gameDays)[i]).getDay() ? (
								<>
									{getDateTimeWithTimezone(games[0].starts_at, games[0].starts_at_timezone).toFormat(
										'EEE MMM dd h:mm a ZZZZ',
									)}{' '}
									| {games[0].ot7_event.city_name}, {abbr(games[0].ot7_event.state)}
								</>
							) : (
								getDateTimeWithTimezone(games[0].starts_at, games[0].starts_at_timezone).toFormat('h:mm a ZZZZ')
							)}
						</Eyebrow>
						<div className={['Games', multiColumn ? 'MultiColumn' : null].filter(Boolean).join(' ')}>
							{games.map((g) => (
								<Game game={g} key={g.id} />
							))}
						</div>
					</Fragment>
				))
			) : (
				<TBD />
			)}
			{!showAll && Object.values(gameDays).length > 0 && nextEvent?.slug ? (
				<div className="More">
					<Button href={`/schedule/${nextEvent?.slug}`}>More Scores</Button>{' '}
				</div>
			) : null}
		</div>
	);
};

export default Games;
