import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import { imgOt7LogoSvg } from '~/src/images';
import './Footer.scss';

const Footer = () => {
	return (
		<footer className="Footer">
			<ContentContainer>
				<nav>
					<div className="LogoContainer">
						<a href="/">
							<img src={imgOt7LogoSvg} />
						</a>
					</div>

					<section>
						<div>League</div>
						<a className="inherit" href="/schedule">
							Schedule
						</a>
						<a className="inherit" href="/teams">
							Teams
						</a>
						<a className="inherit" href="/events">
							Events
						</a>
						<a className="inherit" href="/standings">
							Standings
						</a>
					</section>
					<section>
						<div>About</div>
						<a className="inherit" href="/about">
							OT7
						</a>
						<a className="inherit" href="/media">
							Media & Credentials
						</a>
						<a className="inherit" href="/fan-code-of-conduct">
							Fan Code of Conduct
						</a>
					</section>

					<section>
						<div>Follow</div>
						<div className="Social">
							<a className="inherit" href="https://www.instagram.com/ot7/" title="Instagram">
								<FontAwesomeIcon icon={faInstagram} size="1x" />
							</a>
							<a className="inherit" href="https://www.tiktok.com/@overtime7" title="TikTok">
								<FontAwesomeIcon icon={faTiktok} size="1x" />
							</a>
							<a className="inherit" href="https://www.youtube.com/@OvertimeSZN" title="YouTube">
								<FontAwesomeIcon icon={faYoutube} size="1x" />
							</a>
						</div>
					</section>
				</nav>
			</ContentContainer>
			<div className="LegalContainer">
				<ContentContainer>
					<div>&copy; {new Date().getFullYear()} Overtime. All Rights Reserved.</div>
					<div className="Links">
						<a className="inherit" href="https://overtime.tv/terms">
							Terms
						</a>
						<span>|</span>
						<a className="inherit" href="https://overtime.tv/privacy">
							Privacy Policy
						</a>
						<span>|</span>
						<a className="inherit" href="https://overtime.tv/copyright">
							DMCA
						</a>
					</div>
				</ContentContainer>
			</div>
		</footer>
	);
};
export default Footer;
