import React, { ReactNode } from 'react';
import './Eyebrow.scss';

const Eyebrow = ({
	children,
	fullWidth = false,
	style,
	...props
}: {
	children: ReactNode;
	fullWidth?: boolean;
	style?: any;
}) => (
	<div className={['Eyebrow', fullWidth ? 'FullWidth' : null].filter(Boolean).join(' ')} style={style} {...props}>
		<div className="EyebrowText">{children}</div>
	</div>
);

export default Eyebrow;
