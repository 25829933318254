import { startCase } from 'lodash';
import React from 'react';
import Button from '~/src/components/Button';
import TBD from '~/src/components/TBD';
import { imgOt7LogoBlackSvg } from '~/src/images';
import { Ot7Game, Ot7GamesOt7Team } from '~/src/models/Ot7Game';
import { colorFromInt } from '../../lib';
import './Game.scss';

const enum TeamResult {
	Win = 'win',
	Lose = 'lose',
	Tie = 'tie',
}
const getTeamStatus = (gameStatus, teamPoints, opposingTeamPoints) => {
	return gameStatus !== 'confirmed'
		? TeamResult.Tie
		: opposingTeamPoints < teamPoints
			? TeamResult.Win
			: opposingTeamPoints > teamPoints
				? TeamResult.Lose
				: TeamResult.Tie;
};
const GameTeam = ({
	game,
	gameTeam,
	teamResult,
}: {
	game: Ot7Game;
	gameTeam: Ot7GamesOt7Team;
	teamResult?: TeamResult;
}) => (
	<div className="GameTeam">
		{gameTeam ? (
			<>
				{/* <div>{gameTeam.ot7_team.ot7_seasons_ot7_teams.find((s) => s.ot7_season.is_current).position}</div> */}
				<a className="LogoName inherit" href={`/teams/${gameTeam.ot7_team.id}`}>
					<img
						className={['Logo', gameTeam?.ot7_team?.logo_path ? null : 'Placeholder'].filter(Boolean).join(' ')}
						alt={gameTeam?.ot7_team?.name ?? 'TBD'}
						src={
							gameTeam?.ot7_team?.logo_path
								? `https://images.overtime.tv/ot7-teams/${gameTeam?.ot7_team?.logo_path}`
								: imgOt7LogoBlackSvg
						}
						style={{ backgroundColor: colorFromInt(gameTeam?.ot7_team?.primary_color) }}
					/>
					<div
						className={[
							'Name',
							teamResult === TeamResult.Win ? 'Leader' : null,
							teamResult === TeamResult.Lose ? 'Loser' : null,
						]
							.filter(Boolean)
							.join(' ')}
					>
						{gameTeam?.ot7_team?.name ?? 'TBD'}
					</div>
				</a>
			</>
		) : (
			<TBD />
		)}
		<div
			className={[
				'Points',
				teamResult === TeamResult.Win ? 'Leader' : null,
				teamResult === TeamResult.Lose ? 'Loser' : null,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{gameTeam?.points ?? 0}
		</div>
	</div>
);

const Game = ({ game, showPurpose = false }: { game: Ot7Game; showPurpose?: boolean }) => {
	const homeTeam = game.ot7_games_ot7_teams.find((t) => t.is_home_team);
	const awayTeam = game.ot7_games_ot7_teams.find((t) => !t.is_home_team);
	return (
		<div className={['IndexGame', startCase(game.status).replace(' ', '')].join(' ')}>
			{showPurpose ? <div className="Purpose">{game.purpose}</div> : null}
			<GameTeam
				game={game}
				gameTeam={awayTeam}
				teamResult={getTeamStatus(game.status, awayTeam?.points, homeTeam?.points)}
			/>
			<GameTeam
				game={game}
				gameTeam={homeTeam}
				teamResult={getTeamStatus(game.status, homeTeam?.points, awayTeam?.points)}
			/>
			{game.youtube_id ? (
				<Button inverted={true} size={'small'} href={`https://www.youtube.com/watch?v=${game.youtube_id}`}>
					{['in_progress', 'scheduled'].includes(game.status) ? 'Stream' : 'Watch Replay'}
				</Button>
			) : null}
		</div>
	);
};

export default Game;
