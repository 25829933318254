import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { getDateTimeWithTimezone } from '~/src/lib';
import { Ot7Event } from '~/src/models/Ot7Event';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import { Urls } from '../../lib/enums/urls';
import './Event.scss';

const Event = ({ event }: { event: Ot7Event }) => {
	// use luxon to check if event.end_at is in the past
	const isComplete = DateTime.fromISO(event.ends_at) < DateTime.now();

	return (
		<li className="IndexEvent" key={event.id}>
			<a href={isComplete ? `/schedule/${event.slug}` : `/events/${event.slug}`}>
				<div className="ImageContainer">
					<Picture
						src={`${Urls.OvertimeImagesBaseUrl}/${event.image_path}?format=webp&quality=50`}
						mobileWidth={560}
						alt={event.title}
					/>

					{event.is_sold_out ? (
						<div className="SoldOutOverlay">
							<div>Sold Out</div>
						</div>
					) : null}
				</div>
				<div className="Text">
					<div className="Title">{event.title}</div>

					<div className="Date">
						<>
							{getDateTimeWithTimezone(event.starts_at, event.starts_at_timezone).toFormat('MMM')}{' '}
							{getDateTimeWithTimezone(event.starts_at, event.starts_at_timezone).toFormat('dd')}-
							{getDateTimeWithTimezone(event.ends_at, event.ends_at_timezone).toFormat('dd')},{' '}
							{getDateTimeWithTimezone(event.ends_at, event.ends_at_timezone).toFormat('yyyy')}
						</>
					</div>
					{
						<div className="Tickets">
							{isComplete ? 'View results' : 'Buy Tickets'}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
					}
				</div>
			</a>
		</li>
	);
};

export default Event;
