import React from 'react';
import { Ot7Team } from '~/src/models/Ot7Team';
import { colorFromInt } from '../../lib';
import './Logo.scss';

const Logo = ({ team }: { team: Ot7Team }) => (
	<a href={`/teams/${team.id}`} className="TeamsLogo">
		{team?.small_logo_path ? (
			<img
				src={`https://images.overtime.tv/${team?.small_logo_path}?width=200`}
				style={{ backgroundColor: colorFromInt(team?.primary_color) }}
			/>
		) : team?.logo_path ? (
			<img
				src={`https://images.overtime.tv/${team?.logo_path}?width=200`}
				style={{ backgroundColor: colorFromInt(team?.primary_color) }}
			/>
		) : (
			<div className="Placeholder" style={{ backgroundColor: colorFromInt(team?.primary_color) }}>
				{team?.team_code?.[0] ?? team?.name.slice(0, 1)}
			</div>
		)}
	</a>
);

export default Logo;
