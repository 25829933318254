import { faStoreAlt, faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import { imgOt7NamemarkSvg } from '~/src/images';
import MobileMenu, { MobileMenuContext } from '~/src/overtime-lib/src/www/components/MobileMenu';
import { Urls } from '../lib/enums/urls';
import './Header.scss';

type MenuItem = {
	text: string;
	url: string;
};

const menuItems: MenuItem[] = [
	{ text: 'Schedule', url: '/schedule' },
	{ text: 'Teams', url: '/teams' },
	{ text: 'Season 4', url: '/events' },
	{ text: 'Standings', url: '/standings' },
	{ text: 'Stats', url: '/stats' },
	{ text: 'About', url: '/about' },
];

const _MobileMenuItems = () => {
	const { isVisible } = useContext(MobileMenuContext);
	return (
		<nav>
			{menuItems.map((item, index) => (
				<a key={index} href={item.url} className="mobile-only" tabIndex={!isVisible ? -1 : null}>
					{item.text}
				</a>
			))}
		</nav>
	);
};

const _DesktopMenuItems = () => {
	return (
		<div className="LinksContainer LinksContainer--Desktop">
			<div className="DesktopLinks">
				{menuItems.map((item, index) => (
					<a key={`desktop-menu-${index}`} className="inherit" href={item.url}>
						{item.text}
					</a>
				))}
			</div>
		</div>
	);
};

const Header = () => {
	return (
		<header className="Header">
			<div className="Background" />
			<ContentContainer>
				<div className="HeaderContainer">
					<a className="Logo" href="/" title="Home" tabIndex={1}>
						<img src={imgOt7NamemarkSvg} />
					</a>
					<_DesktopMenuItems />
					<div className="LinksContainer">
						<a className="inherit Tickets" href={Urls.VivenuTicketsSecretShopUrl}>
							<FontAwesomeIcon icon={faTicket} />
							Tickets
						</a>
						<a className="inherit Shop" href={Urls.OvertimeShopUrl}>
							<FontAwesomeIcon icon={faStoreAlt} />
							Shop
						</a>
					</div>
					<MobileMenu>
						<_MobileMenuItems />
					</MobileMenu>
				</div>
			</ContentContainer>
		</header>
	);
};
export default Header;
